const Fetch_Url = "https://99win.me/trova/src/api";

const wingo_Url = "https://99win.me/trova/src/api/wingo_game";

const lucky_Url = "https://99win.me/trova/src/api/card_game";

const dice_Url = "https://99win.me/trova/src/api/dice_game";

const andar_Url = "https://99win.me/trova/src/api/andar_bahar_game";

const mine_Url = "https://99win.me/trova/src/api/mine_game";

export { Fetch_Url, wingo_Url, lucky_Url, dice_Url, andar_Url, mine_Url };